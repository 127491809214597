<template>
  <div style="margin-top: 153px">
    <div style="position: fixed; background-color: #E9ECEF; width: calc(100% - 275px); top: 60px; height: 10px; z-index: 122;"></div>
    <div class="box box-shadow bg-white p-3 pb-4 rounded"
         style="position: fixed; width: calc(100% - 275px); top: 70px; z-index: 1;">
      <div class="row">
        <div class="col-md-3">
          <div  class="form-control" id="reportrange">
            <i class="flaticon-calendar"></i>&nbsp;
            <span></span>
          </div>

          <!--        <div class="input-group mb-md-0 mb-1">
                    <input type="text"
                           id="reportrange"
                           :disabled="loading"
                           class="form-control" />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">
                        <i class="flaticon-calendar"></i>
                      </span>
                    </div>
                  </div>-->
        </div>

        <div class="col-md-4">
          <amazon-profile-select ref="dashboardAdAccount"
                                 :disabled="loading"
                                 :amazonAdAccounts="amazonAdAccounts"
                                 @changed="changeAccount" />

          <!--        <select class="form-control"
                          ref="dashboardAdAccount"
                          v-model="queryParams.profileId"
                          :disabled="loading"
                          @change="changeAccount($event.target.value)">
                    <option value="" default selected>Select Ad Account</option>
                    <option :value="account.profileId"
                            v-for="account in amazonAdAccounts"
                            :key="'ac_'+account.profileId">
                      {{ account.accountInfo.name }} ({{ account.currencyCode }}) ({{ account.countryCode }}) ({{ account.region }})
                    </option>
                  </select>-->
        </div>

        <div class="col-md-3">
          <input type="text"
                 :disabled="loading"
                 v-model="search"
                 class="form-control"
                 placeholder="Search">
        </div>

        <div class="col-md-2">
          <div class="btn-group w-100 w-md-auto">
            <button type="button" class="btn btn-primary">Export</button>
            <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
              <a class="dropdown-item" href="#" @click.prevent="downloadExcel('csv')">CSV</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click.prevent="downloadExcel('xlsx')">XLSX</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <select class="form-control"
                  :disabled="loading"
                  @change="changeDisplayBy"
                  v-model="displayBy">
            <option value="name">Display By Name</option>
            <option value="platform">Display By Platform</option>
            <option value="label">Display By Label</option>
          </select>
        </div>
        <div class="col-md-9">
          <div class="custom-control custom-checkbox mb-md-0 pl-0">
            <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="showAcosAfterBrb">
            <label class="custom-control-label text-black mb-0" for="customCheck1">Show ACoS after Brand Referral Bonus</label>
          </div>
        </div>
      </div>
    </div>

    <div style="position: fixed; background-color: #E9ECEF; width: calc(100% - 275px); top: 202px; height: 10px; z-index: 122;"></div>

    <div class="row" v-if="errorMsg">
      <div class="col-md-12">
        <div class="alert alert-danger border-0" role="alert">
          {{ errorMsg }}
        </div>
      </div>
    </div>

    <!-- STATISTICS BOX BEGIAN -->
    <div class="row layout-spacing"
         style="margin-bottom: 0 !important; padding-bottom: 0 !important;"
         v-if="!loading && campaigns.length">
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-sales-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-dollar-coin"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Spend</p>
                <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalSpends.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-order-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-stats"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Impressions</p>
                <p class="widget-numeric-value">{{ totalImpressions.toLocaleString() }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-customer-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-3d-cube"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Clicks</p>
                <p class="widget-numeric-value">{{ totalClicks.toLocaleString() }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-income-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-money"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">CPC</p>
                <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalCpc.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area data-widgets br-4">
          <div class="widget t-conv-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-cart-1 primary"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Conversions</p>
                <p class="widget-numeric-value">{{ totalPurchase.toLocaleString() }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget t-rev-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-chart-3"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Revenue</p>
                <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalRevenue.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-acos-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-bank-safe-box"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">ACoS</p>
                <p class="widget-numeric-value">{{ calculateACoS(totalRevenue, totalSpends, totalBrb).toLocaleString(undefined, {minimumFractionDigits: 2}) }}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
        <div class="widget-content-area data-widgets br-4">
          <div class="widget t-raocs-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-user-11"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">TACoS</p>
                <p class="widget-numeric-value">Coming Soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- STATISTICS BOX BEGIAN -->


    <!-- STATISTICS TABLE BEGIAN -->
    <div class="text-center mt-3"
         style="margin-top: 0 !important;"
         v-if="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="text-center" v-else-if="!this.campaigns">
      No information here yet…
      <router-link class="text-blue" :to="{name: 'google_wizard'}" v-if="settings.google_user_id">Create a campaign</router-link>
      <router-link class="text-blue" :to="{name: 'create_campaign_facebook'}" v-else>Create a campaign</router-link>
      or
      <router-link class="text-blue" :to="{name: 'mapping'}">Link a campaign</router-link>
    </div>

    <div v-else-if="!loading && !queryParams.profileId">
      Please select an ad account
    </div>


    <template v-for="(group, i) in groupCampaigns"
              v-else
              :key="'group_'+i">
      <div class="row layout-spacing"
           style="margin-top: 0 !important;"
           v-if="group.campaigns.length">
        <div class="col-lg-12">
          <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area rounded" >
              <div class="row">
                <div class="col-lg-12">
                  <!--                <h5 v-if="displayBy !== 'name'">{{ group.title }}</h5>-->

                  <campaign-table :campaigns="group.campaigns"
                                  :id="group.title"
                                  :title="group.title"
                                  :showAcosAfterBrb="showAcosAfterBrb"
                                  :showSummary="displayBy !== 'name'"
                                  @labelUpdated="updateCampaignLabels"
                                  @unlinkCampaign="unlinkCampaign"
                                  @updateCampaignInfo="updateCampaignInfo"
                                  :loadingMetrics="loadingMetrics"
                                  :currentCurrency="currentCurrency"
                                  :currentCurrencyCode="currentCurrencyCode"
                                  :queryParams="queryParams" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import CampaignTable from "@/components/dashboard/CampaignTable.vue";
import AmazonProfileSelect from "@/components/AmazonProfileSelect.vue";
import {mapGetters} from "vuex";
import getSymbolFromCurrency from 'currency-symbol-map'

export default {
  components: {AmazonProfileSelect, CampaignTable},
  data() {
    return {
      search: '',
      dataLoaded: false,
      dataRecheckTimer: null,
      loading: false,
      displayBy: 'name',
      loadingMetrics: false,
      campaigns: [],
      showAcosAfterBrb: false,
      errorMsg: null,
      queryParams: {
        onlyMapped: 1,
        profileId: this.settings?.amazon_profile_id || '',
        startDate: window.moment().subtract(3, "years").startOf("month").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  watch: {
    queryParams: {
      handler(val) {
        this.$store.commit('dashboardModule/setQueryParams', val);
      },
      deep: true
    },
    displayBy(val) {
      this.$store.commit('dashboardModule/setDisplayBy', val);
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      account: 'auth/getAccount',
      settings: 'settings/getSettings',
      labels: 'getLabels'
    }),
    groupCampaigns() {
      let groups = [];

      if (this.displayBy === 'name') {
        groups.push({
          title: 'Campaigns',
          campaigns: this.filteredCampaigns
        })
      } else if (this.displayBy === 'platform') {
        groups.push({
          title: 'Google',
          campaigns: this.filteredCampaigns.filter(campaign => campaign.google_campaign_name)
        })

        groups.push({
          title: 'Facebook',
          campaigns: this.filteredCampaigns.filter(campaign => campaign.fb_campaign_name)
        })

        groups.push({
          title: 'Influencer',
          campaigns: this.filteredCampaigns.filter(campaign => campaign.influencer_name)
        })
      } else if (this.displayBy === 'label') {
        this.labels.forEach(label => {
          groups.push({
            title: label.name,
            campaigns: this.filteredCampaigns.filter(campaign => campaign.labels?.find(l => label.id === l.id))
          })
        });

        groups.push({
          title: 'Unlabeled',
          campaigns: this.filteredCampaigns.filter(campaign => !campaign.labels || !campaign.labels.length)
        })
      }

      return groups;
    },
    filteredCampaigns() {
      let c = [...this.campaigns];

      if (this.search) {
        c = c.filter(campaign => this.getFormattedCampaignName(campaign).toLowerCase().includes(this.search.toLowerCase()));
      }

      return c;
    },
    currentCurrencyCode() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? amazonAccount.currencyCode : '$';
    },
    currentCurrency() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? getSymbolFromCurrency(amazonAccount.currencyCode) : '$';
    },
    totalBrb() {
      return this.campaigns.reduce((total, campaign) => total + campaign.brb, 0);
    },
    totalImpressions() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.impressions;
      });

      return total;
    },
    totalClicks() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.clicks;
      })

      return total;
    },
    totalCpc() {
      let totalSpends = 0;
      let totalClicks = 0;

      this.campaigns.forEach(campaign => {
        totalSpends += !isNaN(campaign.spend) ? campaign.spend : 0;
        totalClicks += !isNaN(campaign.clicks) ? campaign.clicks : 0;
      })

      if (!totalClicks)
        return 0;

      return totalSpends / totalClicks;
    },
    totalSpends() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        if (campaign.spend !== '')
          total += campaign.spend;
      })

      return total;
    },
    totalRevenue() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.total_sale;
      })

      return total;
    },
    totalPurchase() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.total_purchase;
      })

      return total;
    }
  },
  beforeUnmount() {
    clearInterval(this.dataRecheckTimer);
  },
  mounted() {
    this.dataRecheckTimer = setInterval(() => {
      if (!this.loading &&
          !this.dataLoaded &&
          !this.campaigns.length &&
          this.settings && this.settings.amazon_profile_id) {
        this.changeAccount(this.settings.amazon_profile_id);
      }
    }, 3000);

    window.gtag('event', 'screen_view', {
      'screen_name': 'Dashboard'
    });
    // let t = window.moment().subtract(3, "years").startOf("month");
    // let a = window.moment();

    this.displayBy = this.$store.state.dashboardModule.displayBy;

    let t = window.moment(this.$store.state.dashboardModule.queryParams.startDate, 'YYYYMMDD');
    let a = window.moment(this.$store.state.dashboardModule.queryParams.endDate, 'YYYYMMDD');

    let self = this;

    window.$('#reportrange span').html(t.format('MMM D, YYYY') + ' - ' + a.format('MMM D, YYYY'));

    this.queryParams.startDate = this.$store.state.dashboardModule.queryParams.startDate;
    this.queryParams.endDate = this.$store.state.dashboardModule.queryParams.endDate;

    if (this.$store.state.dashboardModule.campaigns.length) {
      this.campaigns = [...this.$store.state.dashboardModule.campaigns];
      this.queryParams = {...this.$store.state.dashboardModule.queryParams};
      this.displayBy = this.$store.state.dashboardModule.displayBy;
    }

    if (this.$store.state.dashboardModule.forceLoad) {
      this.$store.commit('dashboardModule/setForceLoad', false);
      this.loadData();
    }

    window.$("#reportrange").daterangepicker({
        startDate: t,
        endDate: a,
        locale: {
          format: 'MMM D, YYYY'
        },
        ranges: {
          Today: [window.moment(), window.moment()],
          Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
          "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
          "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
          "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
          "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
          "All Time": [window.moment().subtract(3, "years").startOf("month"), window.moment()],
        },
      },
      function (t, a) {
        self.queryParams.startDate = t.format('YYYYMMDD');
        self.queryParams.endDate = a.format('YYYYMMDD');

        let rangeText = t.format('MMM D, YYYY') + ' - ' + a.format('MMM D, YYYY');
        window.$('#reportrange span').html(rangeText);

        self.loadData();
      }
    )
  },
  methods: {
    changeDisplayBy() {
      this.$store.commit('dashboardModule/setDisplayBy', this.displayBy);
    },
    updateCampaignLabels(payload) {
      let campaign = this.campaigns.find(cam => cam.id === payload.id);
      campaign.labels = payload.labels;
    },
    calculateACoS(purchase, spend, brb) {
      if (purchase && !isNaN(spend / purchase)) {

        if (this.showAcosAfterBrb)
          spend -= brb;

        return ((spend / purchase) === Infinity ? 0 : (spend / purchase) * 100).toFixed(2);
      } else
        return 0;
    },
    downloadExcel(type) {
      this.axios.post('/ads/amazon/campaign/excel', {
        data: this.campaigns,
        type: type
      }, {
        responseType: "arraybuffer",
      }).then((response) => {
        const blob = new Blob([response.data], { type: type === 'csv' ? "text/csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "report." + type;
        link.click();
      });
    },
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.profileId = profile;
      this.loadData();
    },
    unlinkCampaign(ids) {
      this.campaigns = this.campaigns.filter(cam => !ids.includes(cam.id));
    },
    updateCampaignInfo(payload) {
      let campaign = this.campaigns.find(cam => cam.id === payload.id);

      campaign.status = payload.status;
    },
    checkMetrics() {
      let googleAccounts = [];
      let fbAccounts = [];
      let twitterCampaigns = [];

      this.campaigns.forEach(campaign => {
        if (campaign.fb_campaign_id) {
          if (!fbAccounts.includes(campaign.fb_account_id))
            fbAccounts.push(campaign.fb_account_id);
        } else if (campaign.google_campaign) {
          if (!googleAccounts.includes(campaign.google_account_id))
            googleAccounts.push(campaign.google_account_id);
        } else if (campaign.twitter_campaign_id) {
          twitterCampaigns.push({
            account_id: campaign.twitter_account_id,
            campaign_id: campaign.twitter_campaign_id
          })
        }
      })

      let reqs = [];

      if (fbAccounts.length) {
        reqs.push(
          this.axios.post('/fb-campaign-spend', {
              accounts: fbAccounts,
              currency: this.currentCurrencyCode,
              start: this.queryParams.startDate,
              end: this.queryParams.endDate,
            })
            .then(res => {
              res.data.forEach(c => {
                let campaigns = this.campaigns.filter(cam => cam.fb_campaign_id == c.campaign_id);

                campaigns.forEach(campaign => {
                  campaign.impressions = parseInt(c.impressions);
                  campaign.clicks = parseInt(c.clicks);
                  campaign.spend = parseFloat(c.spend);

                  if (campaign.clicks)
                    campaign.cpc = campaign.spend / campaign.clicks;
                });
              })

              this.campaigns.forEach(campaign => {
                if (campaign.fb_campaign_id && campaign.spend === '') {
                  campaign.spend = 0;
                }
              })
            })
        );

        reqs.push(
          this.axios.post('/fb-campaign-status', {
              accounts: fbAccounts
            })
            .then(res => {
              res.data.forEach(c => {
                let campaign = this.campaigns.find(cam => cam.fb_campaign_id == c.id);

                if (campaign) {
                  campaign.status = this.getFacebookCampaignStatus(c.status)
                }
              })
            })
        )
      }

      if (googleAccounts.length) {
        reqs.push(
          this.axios.post('/google-campaign-spend', {
              accounts: googleAccounts,
              currency: this.currentCurrencyCode,
              start: this.queryParams.startDate,
              end: this.queryParams.endDate,
            })
            .then(res => {
              res.data.forEach(c => {
                let campaigns = this.campaigns.filter(cam => cam.google_campaign == c.id);

                campaigns.forEach(campaign => {
                  campaign.impressions = c.impressions;
                  campaign.clicks = c.clicks;
                  campaign.cpc = c.cpc;
                  campaign.spend = c.cost;
                  campaign.status = this.getGoogleCampaignStatus(c.status);
                })
              })
            })
        )
      }

      if (twitterCampaigns.length) {
        reqs.push(
            this.axios.post('/twitter/campaigns/analytics', {
              campaigns: twitterCampaigns,
              currency: this.currentCurrencyCode,
              start: this.queryParams.startDate,
              end: this.queryParams.endDate,
            }).then(res => {
              res.data.forEach(c => {
                let campaigns = this.campaigns.filter(cam => cam.twitter_campaign_id == c.campaign_id);

                campaigns.forEach(campaign => {
                  campaign.impressions = c.impressions;
                  campaign.clicks = c.clicks;
                  campaign.cpc = c.clicks / c.billed_charge_local_micro;
                  campaign.spend = c.billed_charge_local_micro;
                })
              })
            })
        )

        reqs.push(
            this.axios.post('/twitter/campaigns/status', {
              campaigns: twitterCampaigns,
            }).then(res => {
              res.data.data.forEach(c => {
                let campaign = this.campaigns.find(cam => cam.twitter_campaign_id == c.campaign_id);

                if (campaign) {
                  campaign.status = this.getTwitterCampaignStatus(c.status);
                }
              })
            })
        )
      }

      Promise.all(reqs).finally(() => {
        this.loadingMetrics = false;
        this.$store.commit('dashboardModule/setCampaigns', [...this.campaigns]);
      });
    },
    loadData() {
      this.loading = true;
      this.campaigns = [];
      this.errorMsg = null;
      this.loadingMetrics = true;

      this.axios.get('ads/amazon/campaign', {
          params: this.queryParams
        })
        .then(response => {
          this.campaigns = response.data.data;

          this.axios.get('/ads/amazon/new-campaign')
            .then(response => {
              let campaigns = response.data.data.filter(cam => !this.campaigns.find(c => c.name === cam.name))
                  .filter(cam => cam.amazon_profile == this.queryParams.profileId)
                .map(cam => {
                  return {
                    id: cam.id,
                    atc: 0,
                    brb: 0,
                    click_through: 0,
                    clicks: 0,
                    dpv: 0,
                    labels: cam.labels,
                    fb_campaign_id: cam.fb_campaign,
                    fb_campaign_name: cam.fb_campaign_name,
                    google_campaign: cam.google_campaign,
                    google_campaign_name: cam.google_campaign_name,
                    google_account_id: cam.google_account_id,
                    google_manager_id: cam.google_manager_id,
                    fb_account_id: cam.fb_account_id,
                    influencer_name: cam.influencer_name,
                    influencer_amount: cam.influencer_amount,
                    influencer_percentage: cam.influencer_percentage,
                    influencer_spend: cam.influencer_spend,
                    influencer_asin: cam.influencer_asin,
                    influencer_campaign_url: cam.influencer_campaign_url,
                    twitter_account_id: cam.twitter_account_id,
                    twitter_campaign_id: cam.twitter_campaign_id,
                    twitter_campaign_name: cam.twitter_campaign_name,
                    impressions: 0,
                    name: cam.name,
                    spend: 0,
                    total_purchase: 0,
                    total_sale: 0,
                  };
                })

              this.campaigns = [...this.campaigns, ...campaigns];

              this.$store.commit('dashboardModule/setCampaigns', [...this.campaigns]);
              this.checkMetrics();
            })
        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => {
          this.loading = false;
          this.dataLoaded = true;
        })

    },
  }
}
</script>