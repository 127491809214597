<template>
  <div class="modal fade" id="change-plan-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content popup-background border-0">
        <div class="modal-body">
          <section class="pricing-section bg-7">
            <div class="row">
              <div class="col-12 text-center">
                <span class="fs-20 mr-3 text-white"
                      style="position: relative; top: -10px">Pay Annually</span>

                <label class="switch s-secondary mt-4">

                  <input type="checkbox" checked="" v-model="monthly">
                  <span class="slider round" style="background-color: #805dca"></span>
                </label>

                <span class="fs-20 ml-3 text-white"
                      style="position: relative; top: -10px">Pay Monthly</span>
              </div>
            </div>

            <div class="pricing pricing--norbu">
              <div class="pricing__item"
                   v-for="card in packages.filter(p => p.monthly === monthly)"
                   :key="card.id">
                <h3 class="pricing__title">{{ card.name }}</h3>
                <div class="pricing__price"><span class="pricing__currency">$</span>{{ card.price }}<span class="pricing__period"> /&nbsp; {{ card.period }}</span></div>
<!--                <h4 class="pricing__original_price mb-4" v-html="card.original_price"></h4>-->

                <div class="cp-spinner cp-round mb-4" v-if="modalLoading"></div>

                <template v-else>
                  <button class="btn btn-danger btn-rounded mb-4"
                          @click="cancelSubscription"
                          v-if="card.price_id === currentPlan.stripe_price">Cancel</button>

                  <button class="btn btn-info btn-rounded mb-4"
                          @click="changePlan(card.price_id)"
                          v-else>Change</button>
                </template>

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
      modalLoading: false,
      monthly: true,
      packages: [
        {
          id: 1,
          name: 'Collab',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_COLLAB_MONTHLY_PRICE_ID,
          price: 35
        },
        {
          id: 2,
          name: 'Full',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_MONTHLY_PRICE_ID,
          price: 199
        },
        {
          id: 3,
          name: 'Collab',
          period: 'Yearly',
          monthly: false,
          price_id: process.env.VUE_APP_STRIPE_COLLAB_YEARLY_PRICE_ID,
          price: 385
        },
        {
          id: 4,
          name: 'Full',
          period: 'Yearly',
          monthly: false,
          price_id: process.env.VUE_APP_STRIPE_YEARLY_PRICE_ID,
          price: 1067
        },
        {
          id: 5,
          name: 'X',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_AMAZON_X_MONTHLY_PRICE_ID,
          price: 99
        },
        {
          id: 6,
          name: 'X',
          period: 'Yearly',
          monthly: false,
          price_id: process.env.VUE_APP_STRIPE_AMAZON_X_YEARLY_PRICE_ID,
          price: 790
        }
      ]
    }
  },
  computed: {
    currentPlanId() {
      if (this.currentPlan)
        return this.packages.find(p => p.price_id === this.currentPlan.stripe_price) ? this.packages.find(p => p.price_id === this.currentPlan.stripe_price).id : null;

      return null;
    },
    ...mapGetters({
      user: 'auth/getUser',
      settings: 'settings/getSettings',
      currentPlan: 'plan/getCurrentPlan',
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('change-plan-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    cancelSubscription() {
      this.$swal({
        title: 'Are you sure want to cancel subscription?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, cancel it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;

          this.axios.post('cancel-plan')
            .then(() => {
              location.reload()
            });
        }
      }).finally(() => this.modalLoading = false);
    },
    changePlan(priceId) {
      this.$swal({
        title: 'Are you sure want to change?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(7,118,124)',
        confirmButtonText: 'Yes, change it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;
          this.axios.post('change-plan', {
            price_id: priceId
          }).then(response => {
            this.hide();
            this.$store.commit('plan/setCurrentPlan', response.data.data);

            if ([process.env.VUE_APP_STRIPE_AMAZON_X_MONTHLY_PRICE_ID, process.env.VUE_APP_STRIPE_AMAZON_X_YEARLY_PRICE_ID].includes(priceId)) {
              const token = this.user.token;
              this.$store.dispatch('auth/resetUser');
              window.location.replace(process.env.VUE_APP_X_FRONT + '/login?token=' + token);
            }
          }).finally(() => {
            this.modalLoading = false;
          })
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../components/subs-popup/style/style";
@import "@/assets/scss/pricing.scss";
</style>